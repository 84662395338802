.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.age span {
  width: 48% !important;
}

.age span + span {
  width: 48% !important;
  float: right;
}

.addpet {
  padding: 50px 10px;
  text-align: center;
}
.addpet h5 {
  margin: 0;
  color: #fff;
}

.MuiMenu-paper {
  top: 60px !important;
  border: 1px solid #ccc;
}

.navbar-custom .navbar-nav > li > a.membermenu {
  padding: 23px 30px;
}
nav.navbar.shrink a.membermenu {
  padding-top: 7px !important;
}
.navbar-custom ul.nav li a.membermenu:hover,
.navbar-custom .nav > .active > a.membermenu {
  padding: 23px 30px;
}
.navbar-custom ul.nav li a.membermenu:hover,
.navbar-custom .nav > .active > a.membermenu {
  padding: 23px 30px;
}
nav.navbar.shrink a.membermenu {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.e-outline {
  background: #ffefd5 !important;
  border: 2px solid #f19f1f !important;
  border-radius: 10px !important;
}
.e-label {
  color: #b1afaf !important;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell,
.e-grid .e-gridcontent,
.e-grid .e-table,
.e-grid .e-content,
.e-grid .e-gridheader,
.e-grid {
  background: transparent !important;
  border-color: #562e29 !important ;
  color: #b1afaf !important;
  font-size: 14px !important;
}
.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell {
  border-color: #562e29 !important;
  color: #b1afaf !important;
  font-size: 14px !important;
}
.e-grid .e-rowcell {
  padding: 12px 20px !important;
}
.e-headertext {
  font-size: 14px;
}
.e-btn.e-icon-btn,
.e-css.e-btn.e-icon-btn {
  padding: 0 7px !important;
}
.e-grid .e-gridheader {
  border-bottom: 2px solid;
  background-color: #562e2980 !important;
}
.e-grid.e-gridhover
  tr[role="row"]:not(.e-editedrow):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: #11333775 !important;
}

.e-grid .e-icons,
.e-btn.e-flat,
.e-css.e-btn.e-flat {
  color: #b1afaf !important;
  font-size: 16px;
}
.e-toolbar .e-toolbar-items,
.e-toolbar {
  background: transparent !important;
  border-top: 1px solid #562e29 !important;
}
/* .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left){
  border-color: #a9a7a7 !important
} */
::-webkit-input-placeholder {
  /* Edge */
  color: #b1afaf !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1afaf !important;
}

::placeholder {
  color: #b1afaf !important;
}
.e-pager {
  background-color: #11333775 !important;
  border-top: 2px solid rgb(133 31 13) !important;
}
.e-pager .e-pagercontainer {
  background: transparent !important;
}
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  color: #b1afaf !important;
}
.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  color: #ef5030 !important;
}
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: rgb(133 31 13) !important;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
  background: rgb(133 31 13) !important; }
label {
  font-weight: normal !important;
}
.pet-img img {
  width: 75px;
  height: 75px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.browse-img {
  background: #ffefd5;
  padding: 9px;
  border: 2px solid #999c9b;
  border-radius: 8px;
  color: #ef5030;
  width: 100%;
}
.e-input {
  color: #282c34 !important;
}
.e-popup-open {
  border-radius: 0 !important;
}
.org-color {
  color: #f19f1f !important;
  font-size: 20px !important;
}

/* #grdTxn_content_table tr:nth-child(2) td:nth-child(7) a{
  display: none !important;
} */
.display-none a {
  display: none;
}
.back {
  font-size: 20px !important;
  float: right;
}
.iconadmin {
  background: none;
  border: 0;
  color: #cd6654 !important; 
}

#grdAdmin .e-search .e-input::placeholder, #grdAdmin .e-search .e-input, #grdAdmin .e-emptyrow {
  color: #fff !important;
}
#grdAdmin .e-dropdownlist {
  color: #fff !important;
}
#grdAdmin input::placeholder {
  color: #d4caca !important;
}
#grdAdmin .e-pagerexternalmsg {
  color:#fff !important;
} 
#grdAdmin .e-filterbar th {
  background: #4b4848 !important;
}
#grdAdmin .e-filterbar th:nth-child(n+3) {
  background: none !important;
} 
#grdAdmin .e-filterbar th:nth-child(n+3) .e-input-group {
  border: none !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), #grdAdmin .e-input-focus{
  border-color: rgb(133 31 13) !important;
}
#grdTxn .e-input::placeholder{
  color: #fff !important
}
.e-grid.e-default .e-gridheader th.e-firstcell,
.e-grid.e-default .e-gridheader th.e-lastcell {
  border-left-width: 0 !important;
}
.btntrans {
  background: transparent;
  border: none;
  font-size: 14px;
  color: #000;
  padding: 0 15px;
  width: 100%;
  text-align: left;
}
.input-border {
  border-color: #d53c1d !important;
  border-radius: 0 !important;
}
#grdTxn .e-input,
#grdTxn .e-emptyrow {
  color: #fff !important;
}
label {
  font-weight: 400 !important;
  color:#fff;
}

::-webkit-input-placeholder { /* Edge */
  color: #222 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #222 !important;
}

input::placeholder {
  color: #222 !important;
}

/* Google map */
.pac-matched {
  color: #000 !important
}
.pac-item span {
  color: #000 !important
}
/* */

.petlocation .e-checkbox-wrapper{
  margin-bottom: 5px;
}
.btnAdd{
  float: right;
  margin: 0 15px 10px 0;
  border: 1px solid #ef5030;
  background: #ef5030;
  color: #fff !important;
}
.deletebtn{
  position: absolute;
  right: 4px;
  top: -11px;
  border-radius: 50%;  
  width: 20px; 
  box-shadow: none;
  outline: none;
  border: 0;
  color: red;  
}
.form-group-pet {
  margin-bottom: 7px; 
}
.delbtn{
  line-height: 16px;
  background: #ffffff;
  border-radius: 50%;
  font-size: 13px;
  padding: 2px 7px;
  color: #db3210;
  border: none;
}

.inputdisable input, .inputdisable button, .inputdisable .e-input-group {
  pointer-events: none !important;  
}
.dropdowndisable {
  pointer-events: none !important;  
}
.createAcc{
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.createAcc p{
  font-size: 13px;
  color: #ffffff;
  margin: 0;
}
.createAcc .linkbtn{
  background-color: #a5341e;
    border: 1px solid #bf3f26;
    padding: 8px 32px;
    margin-top: 0;
    display: inline-block;
    color: #fff;
    width: 100%;
}
.errorMsg {
  width: 100%;
    margin: 6px auto;
    padding: 10px 15px 20px;
    border: 1px solid #7e1a0d;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.6);
    float: left;
}
.warn{
  font-size: 24px;
    color: #db0f16;
    display: flex;
    float: left;
    margin-right: 15px;
    margin-top: 4px;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #ef5030 !important;
  border-color: #ef5030 !important;
}
.btn-primary.focus-visible, .btn-primary:focus-visible{
  outline: none !important;
}

.form-group-new{
  margin-bottom: 5px;
}
.cart { position: relative; float: right; } 
.memberName{
  text-align: right;
    color: rgb(241, 159, 31);
    /* line-height: 13px; */
    display: block;
    margin-top: -2px;
    position: relative;
    float: left;
}
#sidebar {
    display: none;
    position: absolute;
    right: 0;
    z-index: 999;
}
.cart:hover #sidebar {
    display:block;
}
.desktop{
  display: block;
}
.mobile{
  display: none;
}
.col-40{
  width: 40%;
  float: left;
  padding: 0 15px;
}
.col-60{
  width: 60%;
  float: left;
}
.footermenuP{
  color: rgb(0, 0, 0);
    border-bottom: 1px solid #974b3d;
    padding: 3px 0;
    margin-bottom: 3px;
    width: 140px;
    /* text-align: center; */
    margin: 0 auto 3px;
}
.crossbtn{
  border: 1px solid #ef5030;
    background: #fff;
    float: right;
    border-radius: 50%;
    font-weight: bold;
    color: #ef5030;
    margin-top: -11px;
    right: 10px;
    position: absolute;
}
.ReactModal__Content{
  width: 40% !important;
}
#info{
  color: #fff;
}
#info  tr :nth-child(1){
  width: 30%;
    padding: 5px 20px 5px 0;
    vertical-align: top;
    color: #e5e2e2
}
#info  tr :nth-child(2){
  width: 60%;
  padding: 5px 0;  
  vertical-align: top;
}
.btnAdd:hover{
  color: #fff !important
}
@media all and (max-width : 480px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .ReactModal__Content{
    width: 320px !important;
  }
}
.taglineRight {
  position: absolute;
  bottom: 50px;
  right: 90px;
}

#captcha div div {
  float: left;
  margin-left: 10px;
}
#canv {
  float: left;  
}

.loader {
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
}
.loader img {
  width: 100%;
  height: 60px;
}

.giftcards{
  width: 100%;
  height: 234px;
  background: #e9e6e6;
  padding: 10px;
  border: 2px dashed #850f0f;
  color: #4e4a4a;
}
.gift-left-col{
  float: left;
  text-align: center;
  font-size: 16px;
  color: #000;
  padding-right: 15px;
}
.gift-right-col{
  float: left;
  padding: 0 5px 0 15px;
  border-left: 1px solid #ccc;
}
.gift-agent{
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 3px 0 7px;
  clear: both;
  margin: 20px 0 0;
  line-height: normal;  
  display: inline-block;
  color: #000
}
.gift-code-label{
  margin: 0;
  font-size: 14px;
  line-height: normal;
}
.gift-amount{
  font-weight: bold;
}
.gift-code{
  font-size: 24px;
}
.gift-message{
  font-size: 15px;
  line-height: normal;
}
.textmasking{
    border:2px solid rgb(133 31 13) !important;
    background: #ffefd5 !important;
    min-height: 38px !important;
    width: 100% !important;
    color: #000;
    padding: 10px 12px 9px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.textmasking:focus, .textmasking:focus-visible{
  outline: none !important;
  border:2px solid rgb(133 31 13) !important;
  box-shadow: #7e1a0d;
}

.eyestyle {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 24px; 
  color: black;
  cursor: pointer;
}

.react-date-picker {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 15px;
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-block !important;
}
.react-date-picker__wrapper {
  line-height: 37px;
  padding: 0 10px;
  border: 2px solid rgb(133 31 13) !important;
  background: #ffefd5 !important
}
.react-date-picker__wrapper .react-date-picker__inputGroup input:focus-visible { 
  outline: none !important;
}
.react-date-picker__wrapper .react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero, .react-calendar__navigation__label__labelText { 
  color: #000 !important;
}
.react-date-picker__calendar {
  width: 265px !important;       
  color: #686464 !important
}
.react-calendar__tile {
  max-width: 100%;
  padding: 6px !important;
}
.react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #f19f1f !important;
}
.e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover{
  background: #a9a3a3 !important;
}

.red-btn {
  background: #cf1f29;
  padding: 6px 20px;
  color: #fff;
  display: inline-block; 
  border: 1px solid #bd9a89;
  border-radius: 8px;
  font-size: 15px;
}
.red-btn:hover, .red-btn:focus {
  color: #fff !important;
}
.btnlink {
  background: none !important;
  border: none !important;
  color: #f19f1f;
}
#GridMember_excelexport:hover, #GridMember_excelexport:focus {
  background: #fff !important;
}
#GridMember_excelexport .e-excelexport {
  color: #1d6b40 !important;
}

#AdminMemTxnModal {
  width: 70% !important;
}
#AdminMemTxnModal .e-grid .e-headercell, #AdminMemTxnModal .e-grid .e-detailheadercell, #AdminMemTxnModal .e-grid .e-gridcontent, #AdminMemTxnModal .e-grid .e-table, #AdminMemTxnModal .e-grid .e-content, #AdminMemTxnModal .e-grid .e-gridheader, #AdminMemTxnModal .e-grid {
  background: #1f1e1e !important;
}
#AdminMemTxnModal .e-input, #AdminMemTxnModal ::placeholder {
  color: #fff !important;
}
#AdminMemTxnModal .e-emptyrow {
  color: #beb7b7 !important;
}
.e-grid td.e-active {
  background: #252424 !important;
}
.e-grid th.e-headercell[aria-sort=descending] .e-headertext {
  color: #e2860c !important;
}
.e-grid th.e-headercell[aria-sort=ascending] .e-headertext {
  color: #e2860c !important;
}

/* For React Editor */
.quill {
  background: #fff;
  color: #282c34;
}
.ql-editor {
  min-height: 180px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #000 !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  color: #000 !important;
}
.previewbtn {
  padding: 4px 10px 6px;
  position: absolute;
  right: 21px;
  border: 1px solid #ef5030;
  top: 30px;
  background: #fff;
  color: #ef5030;
}
#PreviewModal {
  width: 60% !important;
}
#PreviewModal .modalcontent {
  max-height: 495px;
  overflow-y: auto;
}
/* End */