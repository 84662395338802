@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.int-bg{
  background-image:"url('img/int-bg.jpg'); background-size: 100% auto; padding: 15px 0;"
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.age span {
  width: 48% !important;
}

.age span + span {
  width: 48% !important;
  float: right;
}

.addpet {
  padding: 50px 10px;
  text-align: center;
}
.addpet h5 {
  margin: 0;
  color: #fff;
}

.MuiMenu-paper {
  top: 60px !important;
  border: 1px solid #ccc;
}

.navbar-custom .navbar-nav > li > a.membermenu {
  padding: 23px 30px;
}
nav.navbar.shrink a.membermenu {
  padding-top: 7px !important;
}
.navbar-custom ul.nav li a.membermenu:hover,
.navbar-custom .nav > .active > a.membermenu {
  padding: 23px 30px;
}
.navbar-custom ul.nav li a.membermenu:hover,
.navbar-custom .nav > .active > a.membermenu {
  padding: 23px 30px;
}
nav.navbar.shrink a.membermenu {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.e-outline {
  background: #ffefd5 !important;
  border: 2px solid #f19f1f !important;
  border-radius: 10px !important;
}
.e-label {
  color: #b1afaf !important;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell,
.e-grid .e-gridcontent,
.e-grid .e-table,
.e-grid .e-content,
.e-grid .e-gridheader,
.e-grid {
  background: transparent !important;
  border-color: #562e29 !important ;
  color: #b1afaf !important;
  font-size: 14px !important;
}
.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell {
  border-color: #562e29 !important;
  color: #b1afaf !important;
  font-size: 14px !important;
}
.e-grid .e-rowcell {
  padding: 12px 20px !important;
}
.e-headertext {
  font-size: 14px;
}
.e-btn.e-icon-btn,
.e-css.e-btn.e-icon-btn {
  padding: 0 7px !important;
}
.e-grid .e-gridheader {
  border-bottom: 2px solid;
  background-color: #562e2980 !important;
}
.e-grid.e-gridhover
  tr[role="row"]:not(.e-editedrow):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: #11333775 !important;
}

.e-grid .e-icons,
.e-btn.e-flat,
.e-css.e-btn.e-flat {
  color: #b1afaf !important;
  font-size: 16px;
}
.e-toolbar .e-toolbar-items,
.e-toolbar {
  background: transparent !important;
  border-top: 1px solid #562e29 !important;
}
/* .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left){
  border-color: #a9a7a7 !important
} */
::-webkit-input-placeholder {
  /* Edge */
  color: #b1afaf !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1afaf !important;
}

::placeholder {
  color: #b1afaf !important;
}
.e-pager {
  background-color: #11333775 !important;
  border-top: 2px solid rgb(133 31 13) !important;
}
.e-pager .e-pagercontainer {
  background: transparent !important;
}
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  color: #b1afaf !important;
}
.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  color: #ef5030 !important;
}
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: rgb(133 31 13) !important;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
  background: rgb(133 31 13) !important; }
label {
  font-weight: normal !important;
}
.pet-img img {
  width: 75px;
  height: 75px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.browse-img {
  background: #ffefd5;
  padding: 9px;
  border: 2px solid #999c9b;
  border-radius: 8px;
  color: #ef5030;
  width: 100%;
}
.e-input {
  color: #282c34 !important;
}
.e-popup-open {
  border-radius: 0 !important;
}
.org-color {
  color: #f19f1f !important;
  font-size: 20px !important;
}

/* #grdTxn_content_table tr:nth-child(2) td:nth-child(7) a{
  display: none !important;
} */
.display-none a {
  display: none;
}
.back {
  font-size: 20px !important;
  float: right;
}
.iconadmin {
  background: none;
  border: 0;
  color: #cd6654 !important; 
}

#grdAdmin .e-search .e-input::-webkit-input-placeholder {
  color: #fff !important;
}

#grdAdmin .e-search .e-input:-ms-input-placeholder {
  color: #fff !important;
}

#grdAdmin .e-search .e-input::placeholder, #grdAdmin .e-search .e-input, #grdAdmin .e-emptyrow {
  color: #fff !important;
}
#grdAdmin .e-dropdownlist {
  color: #fff !important;
}
#grdAdmin input::-webkit-input-placeholder {
  color: #d4caca !important;
}
#grdAdmin input:-ms-input-placeholder {
  color: #d4caca !important;
}
#grdAdmin input::placeholder {
  color: #d4caca !important;
}
#grdAdmin .e-pagerexternalmsg {
  color:#fff !important;
} 
#grdAdmin .e-filterbar th {
  background: #4b4848 !important;
}
#grdAdmin .e-filterbar th:nth-child(n+3) {
  background: none !important;
} 
#grdAdmin .e-filterbar th:nth-child(n+3) .e-input-group {
  border: none !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), #grdAdmin .e-input-focus{
  border-color: rgb(133 31 13) !important;
}
#grdTxn .e-input::-webkit-input-placeholder{
  color: #fff !important
}
#grdTxn .e-input:-ms-input-placeholder{
  color: #fff !important
}
#grdTxn .e-input::placeholder{
  color: #fff !important
}
.e-grid.e-default .e-gridheader th.e-firstcell,
.e-grid.e-default .e-gridheader th.e-lastcell {
  border-left-width: 0 !important;
}
.btntrans {
  background: transparent;
  border: none;
  font-size: 14px;
  color: #000;
  padding: 0 15px;
  width: 100%;
  text-align: left;
}
.input-border {
  border-color: #d53c1d !important;
  border-radius: 0 !important;
}
#grdTxn .e-input,
#grdTxn .e-emptyrow {
  color: #fff !important;
}
label {
  font-weight: 400 !important;
  color:#fff;
}

::-webkit-input-placeholder { /* Edge */
  color: #222 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #222 !important;
}

input::-webkit-input-placeholder {
  color: #222 !important;
}

input:-ms-input-placeholder {
  color: #222 !important;
}

input::placeholder {
  color: #222 !important;
}

/* Google map */
.pac-matched {
  color: #000 !important
}
.pac-item span {
  color: #000 !important
}
/* */

.petlocation .e-checkbox-wrapper{
  margin-bottom: 5px;
}
.btnAdd{
  float: right;
  margin: 0 15px 10px 0;
  border: 1px solid #ef5030;
  background: #ef5030;
  color: #fff !important;
}
.deletebtn{
  position: absolute;
  right: 4px;
  top: -11px;
  border-radius: 50%;  
  width: 20px; 
  box-shadow: none;
  outline: none;
  border: 0;
  color: red;  
}
.form-group-pet {
  margin-bottom: 7px; 
}
.delbtn{
  line-height: 16px;
  background: #ffffff;
  border-radius: 50%;
  font-size: 13px;
  padding: 2px 7px;
  color: #db3210;
  border: none;
}

.inputdisable input, .inputdisable button, .inputdisable .e-input-group {
  pointer-events: none !important;  
}
.dropdowndisable {
  pointer-events: none !important;  
}
.createAcc{
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.createAcc p{
  font-size: 13px;
  color: #ffffff;
  margin: 0;
}
.createAcc .linkbtn{
  background-color: #a5341e;
    border: 1px solid #bf3f26;
    padding: 8px 32px;
    margin-top: 0;
    display: inline-block;
    color: #fff;
    width: 100%;
}
.errorMsg {
  width: 100%;
    margin: 6px auto;
    padding: 10px 15px 20px;
    border: 1px solid #7e1a0d;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.6);
    float: left;
}
.warn{
  font-size: 24px;
    color: #db0f16;
    display: flex;
    float: left;
    margin-right: 15px;
    margin-top: 4px;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #ef5030 !important;
  border-color: #ef5030 !important;
}
.btn-primary.focus-visible, .btn-primary:focus-visible{
  outline: none !important;
}

.form-group-new{
  margin-bottom: 5px;
}
.cart { position: relative; float: right; } 
.memberName{
  text-align: right;
    color: rgb(241, 159, 31);
    /* line-height: 13px; */
    display: block;
    margin-top: -2px;
    position: relative;
    float: left;
}
#sidebar {
    display: none;
    position: absolute;
    right: 0;
    z-index: 999;
}
.cart:hover #sidebar {
    display:block;
}
.desktop{
  display: block;
}
.mobile{
  display: none;
}
.col-40{
  width: 40%;
  float: left;
  padding: 0 15px;
}
.col-60{
  width: 60%;
  float: left;
}
.footermenuP{
  color: rgb(0, 0, 0);
    border-bottom: 1px solid #974b3d;
    padding: 3px 0;
    margin-bottom: 3px;
    width: 140px;
    /* text-align: center; */
    margin: 0 auto 3px;
}
.crossbtn{
  border: 1px solid #ef5030;
    background: #fff;
    float: right;
    border-radius: 50%;
    font-weight: bold;
    color: #ef5030;
    margin-top: -11px;
    right: 10px;
    position: absolute;
}
.ReactModal__Content{
  width: 40% !important;
}
#info{
  color: #fff;
}
#info  tr :nth-child(1){
  width: 30%;
    padding: 5px 20px 5px 0;
    vertical-align: top;
    color: #e5e2e2
}
#info  tr :nth-child(2){
  width: 60%;
  padding: 5px 0;  
  vertical-align: top;
}
.btnAdd:hover{
  color: #fff !important
}
@media all and (max-width : 480px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .ReactModal__Content{
    width: 320px !important;
  }
}
.taglineRight {
  position: absolute;
  bottom: 50px;
  right: 90px;
}

#captcha div div {
  float: left;
  margin-left: 10px;
}
#canv {
  float: left;  
}

.loader {
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
}
.loader img {
  width: 100%;
  height: 60px;
}

.giftcards{
  width: 100%;
  height: 234px;
  background: #e9e6e6;
  padding: 10px;
  border: 2px dashed #850f0f;
  color: #4e4a4a;
}
.gift-left-col{
  float: left;
  text-align: center;
  font-size: 16px;
  color: #000;
  padding-right: 15px;
}
.gift-right-col{
  float: left;
  padding: 0 5px 0 15px;
  border-left: 1px solid #ccc;
}
.gift-agent{
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 3px 0 7px;
  clear: both;
  margin: 20px 0 0;
  line-height: normal;  
  display: inline-block;
  color: #000
}
.gift-code-label{
  margin: 0;
  font-size: 14px;
  line-height: normal;
}
.gift-amount{
  font-weight: bold;
}
.gift-code{
  font-size: 24px;
}
.gift-message{
  font-size: 15px;
  line-height: normal;
}
.textmasking{
    border:2px solid rgb(133 31 13) !important;
    background: #ffefd5 !important;
    min-height: 38px !important;
    width: 100% !important;
    color: #000;
    padding: 10px 12px 9px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.textmasking:focus, .textmasking:focus-visible{
  outline: none !important;
  border:2px solid rgb(133 31 13) !important;
  box-shadow: #7e1a0d;
}

.eyestyle {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 24px; 
  color: black;
  cursor: pointer;
}

.react-date-picker {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 15px;
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-block !important;
}
.react-date-picker__wrapper {
  line-height: 37px;
  padding: 0 10px;
  border: 2px solid rgb(133 31 13) !important;
  background: #ffefd5 !important
}
.react-date-picker__wrapper .react-date-picker__inputGroup input:focus-visible { 
  outline: none !important;
}
.react-date-picker__wrapper .react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero, .react-calendar__navigation__label__labelText { 
  color: #000 !important;
}
.react-date-picker__calendar {
  width: 265px !important;       
  color: #686464 !important
}
.react-calendar__tile {
  max-width: 100%;
  padding: 6px !important;
}
.react-calendar__tile--active, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #f19f1f !important;
}
.e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover{
  background: #a9a3a3 !important;
}

.red-btn {
  background: #cf1f29;
  padding: 6px 20px;
  color: #fff;
  display: inline-block; 
  border: 1px solid #bd9a89;
  border-radius: 8px;
  font-size: 15px;
}
.red-btn:hover, .red-btn:focus {
  color: #fff !important;
}
.btnlink {
  background: none !important;
  border: none !important;
  color: #f19f1f;
}
#GridMember_excelexport:hover, #GridMember_excelexport:focus {
  background: #fff !important;
}
#GridMember_excelexport .e-excelexport {
  color: #1d6b40 !important;
}

#AdminMemTxnModal {
  width: 70% !important;
}
#AdminMemTxnModal .e-grid .e-headercell, #AdminMemTxnModal .e-grid .e-detailheadercell, #AdminMemTxnModal .e-grid .e-gridcontent, #AdminMemTxnModal .e-grid .e-table, #AdminMemTxnModal .e-grid .e-content, #AdminMemTxnModal .e-grid .e-gridheader, #AdminMemTxnModal .e-grid {
  background: #1f1e1e !important;
}
#AdminMemTxnModal ::-webkit-input-placeholder {
  color: #fff !important;
}
#AdminMemTxnModal :-ms-input-placeholder {
  color: #fff !important;
}
#AdminMemTxnModal .e-input, #AdminMemTxnModal ::placeholder {
  color: #fff !important;
}
#AdminMemTxnModal .e-emptyrow {
  color: #beb7b7 !important;
}
.e-grid td.e-active {
  background: #252424 !important;
}
.e-grid th.e-headercell[aria-sort=descending] .e-headertext {
  color: #e2860c !important;
}
.e-grid th.e-headercell[aria-sort=ascending] .e-headertext {
  color: #e2860c !important;
}

/* For React Editor */
.quill {
  background: #fff;
  color: #282c34;
}
.ql-editor {
  min-height: 180px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #000 !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  color: #000 !important;
}
.previewbtn {
  padding: 4px 10px 6px;
  position: absolute;
  right: 21px;
  border: 1px solid #ef5030;
  top: 30px;
  background: #fff;
  color: #ef5030;
}
#PreviewModal {
  width: 60% !important;
}
#PreviewModal .modalcontent {
  max-height: 495px;
  overflow-y: auto;
}
/* End */

/* Menu menu-extra */
.menu.menu-extra li a {
	color: #3c3a42;
	padding: 0px 0px 0px 20px;
	line-height: 80px;
	height: 80px;
}

.menu.menu-extra li a:hover {
	color: #fff;
}

/* Navigation */
.nav-wrap {
	width: 100%
}

.nav-wrap .mainnav,
.nav-wrap .menu.menu-extra {
	float: right;
}

.nav-wrap .menu.menu-extra {
	padding-left: 35px;
}

#mainnav > ul {
	text-align: right;
}

#mainnav ul {
   list-style: none;
   margin: 0;
   padding: 0;
}

#mainnav ul li {
   position: relative;
}

#mainnav > ul > li {
   display: inline-block;   
}

    #mainnav > ul > li > a {
        
        color: #c3bfc0;
        text-transform: none;
        line-height: 56px;
        padding: 0 14px 0 13px;
        font-size: 15px;
        font-weight: normal;
    }

#mainnav > ul > li:last-child > a {
	padding: 0 0 0 16px;	
}

/* submenu */
#mainnav ul.submenu {
    text-align: left;
    position: absolute;
    left: 16px;
    top: 150%;
    width: 240px;
    padding: 18px 0px 16px 0px;
    background-color: #f3f1f1;
    z-index: 9999;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

#mainnav ul li:hover > ul.submenu {
	top: 100%;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
   visibility: visible;
}

#mainnav ul li ul li {
   margin-left: 0;
}

#mainnav ul.submenu li ul {
   position: absolute;
   left: 300px;
   top: 0 !important;
}

#mainnav ul.submenu > li.sub-parent:after {
	content: "\f105";
   	font-family: "FontAwesome";
   	font-size: 14px;
   	position: absolute;
   	right: 25px;
   	top: 11px;
   	color: #999999;
	   transition: all 0.3s ease-in-out;

}

#mainnav ul.submenu > li > a {
    display: block;    
    color: #3c3a42;
    padding: 0 0 0 30px !important;
    line-height: 14px;
    font-size: 15px;
    font-weight: normal;
	   transition: all 0.3s ease-in-out;
}

    #mainnav ul.submenu > li > a:hover {
        color: #cf1f29;
        padding: 0 0 0 40px;
    }

#mainnav ul.submenu > li.sub-parent:hover::after {
	right: 18px;
}

#mainnav > ul > li > a:hover,
#mainnav > ul > li.active > a {
   color: #fff;
   position: relative;
}

/* Hover Menu */
#mainnav > ul > li > a {
	position: relative;
}

/* Mobile navigation
---------------------------------------- */
#mainnav-mobi {
   	display: block;
   	margin: 0 auto;
   	width: 100%;
   	position: absolute;
   	background-color: #6a7d9d;
   	z-index: 1000;
       top: 65px;
       left: 0;
}

#mainnav-mobi ul {
   	display: block;
   	list-style: none;
   	margin: 0;
   	padding: 0;
}

#mainnav-mobi ul li {
   	margin:0;
   	position: relative;
   	text-align: left;
   	border-top: 1px solid #2d374a;
   	cursor: pointer
}

#mainnav-mobi ul > li > a {
   	text-decoration: none;
   	height: 50px;
   	line-height: 50px;
   	padding: 0 15px;
   	color: #fff;
}

#mainnav-mobi ul.sub-menu {
   	top: 100%;
   	left: 0;
   	z-index: 2000;
   	position: relative;
   	background-color: #333333;
}

#mainnav-mobi > ul > li > ul > li,
#mainnav-mobi > ul > li > ul > li > ul > li {
   	position: relative;
   	border-top: 1px solid #333333;
   	padding-left: 15px;
}

#mainnav-mobi > ul > li > ul > li > ul > li a {
   	padding-left: 45px !important
}

#mainnav-mobi ul.sub-menu > li > a {
   	display: block;
   	text-decoration: none;
   	padding: 0 60px;
   	border-top-color: rgba(255,255,255,.1);
	transition: all 0.2s ease-out;
}

#mainnav-mobi > ul > li > ul > li:first-child a {
	border-top: none;
}

#mainnav-mobi ul.sub-menu > li > a:hover,
#mainnav-mobi > ul > li > ul > li.active > a {
	color: #fff;
}

/* Button menu mobile */
.btn-menu {
	display: none;
	position: absolute;
    top: 0;
    right: 0;
	background: transparent;
	cursor: pointer;
	margin: 15px 10px 32px 40px;
	width: 26px;
	height: 16px;
	float: right;
    transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after, 
.btn-menu span {
	background-color: #fff;
    transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after {
	content: '';
	position: absolute;
	top: 0;
	height: 2px;
	width: 100%;
	left: 0;
	top: 50%;
	transform-origin: 50% 50%;
}

.btn-menu span {
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	top: 50%;
	overflow: hidden;
	text-indent: 200%;
}

.btn-menu:before {
	transform: translate3d(0, -7px, 0);
}

.btn-menu:after {
 transform: translate3d(0, 7px, 0);
}

.btn-menu.active span {
	opacity: 0;
}

.btn-menu.active:before {
 transform: rotate3d(0, 0, 1, 45deg);
}

.btn-menu.active:after {
 transform: rotate3d(0, 0, 1, -45deg);
}

.btn-submenu {
   position: absolute;
   right: 0;
   top: 0;
   font: 20px/50px 'FontAwesome';
   text-align: center;
   cursor: pointer;
   width: 70px;
   height: 44px;
}

.btn-submenu:before {
   content: "\f107";
   color: #fff;
}

.btn-submenu.active:before {
   content: "\f106"
}

.btn-menu {
   display: none 
}

/* Smaller than standard 1200 */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    #mainnav > ul > li > a {
        padding: 0px 8px;
    }

    #mainnav > ul > li:nth-child(4) > a {
        padding-right: 8px;
    }

    .nav-wrap .menu.menu-extra {
        padding-left: 0px;
    }
}
    

    /* Smaller than standard 992 */
    @media only screen and (max-width: 991px) {
        .btn-menu {
            display: block;
        }

        #mainnav-mobi .menu {
            overflow-y: auto;
            /* height: 70vh; */
            padding-bottom: 10px;
        }

        .mainnav {
            display: none;
        }
}

        /* All Mobile Sizes */
        @media only screen and (max-width: 767px) {
            .nav-wrap .top-search {
                right: -200%;
            }
        }   

#payment-element {
  margin-bottom: 24px;
}

#payment-message {
  color: rgb(241, 159, 31);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-input {
  padding: 0.75rem;
  background-color:#fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  display: block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 15px;
  color: #30313d
}

.payment-input::-webkit-input-placeholder {
  color:#a9aaae !important
}

.payment-input:-ms-input-placeholder {
  color:#a9aaae !important
}

.payment-input::placeholder {
  color:#a9aaae !important
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 18px;
  /* text-indent: -99999px; */
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
