/* Menu menu-extra */
.menu.menu-extra li a {
	color: #3c3a42;
	padding: 0px 0px 0px 20px;
	line-height: 80px;
	height: 80px;
}

.menu.menu-extra li a:hover {
	color: #fff;
}

/* Navigation */
.nav-wrap {
	width: 100%
}

.nav-wrap .mainnav,
.nav-wrap .menu.menu-extra {
	float: right;
}

.nav-wrap .menu.menu-extra {
	padding-left: 35px;
}

#mainnav > ul {
	text-align: right;
}

#mainnav ul {
   list-style: none;
   margin: 0;
   padding: 0;
}

#mainnav ul li {
   position: relative;
}

#mainnav > ul > li {
   display: inline-block;   
}

    #mainnav > ul > li > a {
        
        color: #c3bfc0;
        text-transform: none;
        line-height: 56px;
        padding: 0 14px 0 13px;
        font-size: 15px;
        font-weight: normal;
    }

#mainnav > ul > li:last-child > a {
	padding: 0 0 0 16px;	
}

/* submenu */
#mainnav ul.submenu {
    text-align: left;
    position: absolute;
    left: 16px;
    top: 150%;
    width: 240px;
    padding: 18px 0px 16px 0px;
    background-color: #f3f1f1;
    z-index: 9999;
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#mainnav ul li:hover > ul.submenu {
	top: 100%;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
   visibility: visible;
}

#mainnav ul li ul li {
   margin-left: 0;
}

#mainnav ul.submenu li ul {
   position: absolute;
   left: 300px;
   top: 0 !important;
}

#mainnav ul.submenu > li.sub-parent:after {
	content: "\f105";
   	font-family: "FontAwesome";
   	font-size: 14px;
   	position: absolute;
   	right: 25px;
   	top: 11px;
   	color: #999999;
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;

}

#mainnav ul.submenu > li > a {
    display: block;    
    color: #3c3a42;
    padding: 0 0 0 30px !important;
    line-height: 14px;
    font-size: 15px;
    font-weight: normal;
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

    #mainnav ul.submenu > li > a:hover {
        color: #cf1f29;
        padding: 0 0 0 40px;
    }

#mainnav ul.submenu > li.sub-parent:hover::after {
	right: 18px;
}

#mainnav > ul > li > a:hover,
#mainnav > ul > li.active > a {
   color: #fff;
   position: relative;
}

/* Hover Menu */
#mainnav > ul > li > a {
	position: relative;
}

/* Mobile navigation
---------------------------------------- */
#mainnav-mobi {
   	display: block;
   	margin: 0 auto;
   	width: 100%;
   	position: absolute;
   	background-color: #6a7d9d;
   	z-index: 1000;
       top: 65px;
       left: 0;
}

#mainnav-mobi ul {
   	display: block;
   	list-style: none;
   	margin: 0;
   	padding: 0;
}

#mainnav-mobi ul li {
   	margin:0;
   	position: relative;
   	text-align: left;
   	border-top: 1px solid #2d374a;
   	cursor: pointer
}

#mainnav-mobi ul > li > a {
   	text-decoration: none;
   	height: 50px;
   	line-height: 50px;
   	padding: 0 15px;
   	color: #fff;
}

#mainnav-mobi ul.sub-menu {
   	top: 100%;
   	left: 0;
   	z-index: 2000;
   	position: relative;
   	background-color: #333333;
}

#mainnav-mobi > ul > li > ul > li,
#mainnav-mobi > ul > li > ul > li > ul > li {
   	position: relative;
   	border-top: 1px solid #333333;
   	padding-left: 15px;
}

#mainnav-mobi > ul > li > ul > li > ul > li a {
   	padding-left: 45px !important
}

#mainnav-mobi ul.sub-menu > li > a {
   	display: block;
   	text-decoration: none;
   	padding: 0 60px;
   	border-top-color: rgba(255,255,255,.1);
	-webkit-transition: all 0.2s ease-out;
	   -moz-transition: all 0.2s ease-out;
	     -o-transition: all 0.2s ease-out;
	        transition: all 0.2s ease-out;
}

#mainnav-mobi > ul > li > ul > li:first-child a {
	border-top: none;
}

#mainnav-mobi ul.sub-menu > li > a:hover,
#mainnav-mobi > ul > li > ul > li.active > a {
	color: #fff;
}

/* Button menu mobile */
.btn-menu {
	display: none;
	position: absolute;
    top: 0;
    right: 0;
	background: transparent;
	cursor: pointer;
	margin: 15px 10px 32px 40px;
	width: 26px;
	height: 16px;
	float: right;
    -webkit-transition: all ease .238s;
       -moz-transition: all ease .238s;
            transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after, 
.btn-menu span {
	background-color: #fff;
    -webkit-transition: all ease .238s;
       -moz-transition: all ease .238s;
            transition: all ease .238s;
}

.btn-menu:before,
.btn-menu:after {
	content: '';
	position: absolute;
	top: 0;
	height: 2px;
	width: 100%;
	left: 0;
	top: 50%;
	-webkit-transform-origin: 50% 50%;
	    -ms-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
}

.btn-menu span {
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	top: 50%;
	overflow: hidden;
	text-indent: 200%;
}

.btn-menu:before {
	-webkit-transform: translate3d(0, -7px, 0);
	        transform: translate3d(0, -7px, 0);
}

.btn-menu:after {
	-webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
}

.btn-menu.active span {
	opacity: 0;
}

.btn-menu.active:before {
	-webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg);
}

.btn-menu.active:after {
	-webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
}

.btn-submenu {
   position: absolute;
   right: 0;
   top: 0;
   font: 20px/50px 'FontAwesome';
   text-align: center;
   cursor: pointer;
   width: 70px;
   height: 44px;
}

.btn-submenu:before {
   content: "\f107";
   color: #fff;
}

.btn-submenu.active:before {
   content: "\f106"
}

.btn-menu {
   display: none 
}

/* Smaller than standard 1200 */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    #mainnav > ul > li > a {
        padding: 0px 8px;
    }

    #mainnav > ul > li:nth-child(4) > a {
        padding-right: 8px;
    }

    .nav-wrap .menu.menu-extra {
        padding-left: 0px;
    }
}
    

    /* Smaller than standard 992 */
    @media only screen and (max-width: 991px) {
        .btn-menu {
            display: block;
        }

        #mainnav-mobi .menu {
            overflow-y: auto;
            /* height: 70vh; */
            padding-bottom: 10px;
        }

        .mainnav {
            display: none;
        }
}

        /* All Mobile Sizes */
        @media only screen and (max-width: 767px) {
            .nav-wrap .top-search {
                right: -200%;
            }
        }   
